<style lang="scss" scoped>
.legal-notice {
  background-color: white;
  strong {
    color: inherit;
    font-weight: 600;
  }

  p {
    padding: 0.25rem 0;
  }

  .policy-title {
    font-size: 2rem;
    margin: 1rem 0;
    border-bottom: solid 1px $secondary;
    color: $primary-dark;
  }

  .policy-subtitle {
    font-size: 1.25rem;
    padding: 0.5rem 0;
  }

  a {
    color: inherit;
  }

  .text-muted {
    opacity: 0.5;
  }
}
</style>

<template>
  <header class="modal-header">
    <h1 class="modal-title">Mentions légales</h1>
  </header>
  <main class="modal-content legal-notice">
    <p>
      Merci de lire avec attention les différentes modalités d’utilisation du
      présent site avant d’y parcourir ses pages. En vous connectant sur ce
      site, vous acceptez sans réserves les présentes modalités. Aussi,
      conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la
      confiance dans l’économie numérique, les responsables du présent site
      internet
      <a target="_blank" rel="noopener" href="http://www.patrol-paris.com"
        >www.patrol-paris.com</a
      >
      sont :
    </p>

    <h2 class="policy-title">Editeur du site</h2>
    <h3 class="policy-subtitle">SAS ROSTAND PONS</h3>
    <p>Numéro de SIRET : 997 526 421 000 18</p>
    <p>Adresse : 2 place Edmond Rostand 75006 PARIS</p>
    <p>Téléphone : 01 83 75 44 50 - Fax : 01 83 75 44 50</p>
    <p>Email : contact@patrol-paris.com</p>
    <p>
      Site Web :<a
        target="_blank"
        rel="noopener"
        href="https://www.patrol-paris.com"
        >www.patrol-paris.com</a
      >
    </p>

    <h2 class="policy-title">Hébérgement & Nom de domaine</h2>
    <p>
      <strong>O2switch</strong> (<a
        target="_blank"
        rel="noopener"
        href="http://www.o2switch.fr"
        >www.o2switch.fr</a
      >) 222-224 Boulevard Gustave Flaubert 63000 Clermont-Ferrand
    </p>

    <p>
      <strong>OVH Cloud</strong> (<a
        target="_blank"
        rel="noopener"
        href="http://www.ovh.com"
        >www.ovh.com</a
      >) 2 rue kellermann BP 80157 59053 ROUBAIX CEDEX 1
    </p>

    <h2 class="policy-title">Création</h2>
    <p>
      Créateur du site : <strong>Kreatik Art</strong> (<a
        target="_blank"
        rel="noopener"
        href="http://www.kreatikart.com"
        >www.kreatikart.com</a
      >) | <strong>Contact:</strong>
      <a href="mailto:contact@kreatikart.com">contact@kreatikart.com</a>
    </p>

    <p>Direction artistique : <strong>Omari Communication</strong></p>

    <p>
      Photographes : <strong>Ilya Kagan</strong> &
      <strong>Gilles Trillard</strong>
    </p>

    <h2 class="policy-title">Contiditions d'utilisation</h2>
    <p>
      Le site
      <a target="_blank" rel="noopener" href="http://www.patrol-paris.com"
        >www.patrol-paris.com</a
      >
      est proposé en différents langages web (HTML, HTML5, Javascript, CSS, PHP,
      etc…) pour un meilleur confort d'utilisation et un graphisme plus
      agréable, nous vous recommandons de recourir à des navigateurs modernes
      comme Internet explorer, Safari, Firefox, Google Chrome, etc…
    </p>

    <p>
      <strong>Mises à jour :</strong>
      SAS Groupe Bourdoncle met en œuvre tous les moyens dont elle dispose, pour
      assurer une information fiable et une mise à jour fiable de ses sites
      internet. Toutefois, des erreurs ou omissions peuvent survenir.
      L'internaute devra donc s'assurer de l'exactitude des informations auprès
      de SAS Groupe Bourdoncle , et signaler toutes modifications du site qu'il
      jugerait utile. SAS Groupe Bourdoncle n'est en aucun cas responsable de
      l'utilisation faite de ces informations, et de tout préjudice direct ou
      indirect pouvant en découler.
    </p>

    <p>
      <strong>Cookies :</strong>
      Le site
      <a target="_blank" rel="noopener" href="http://www.patrol-paris.com"
        >www.patrol-paris.com</a
      >
      peut-être amené à vous demander l’acceptation des cookies pour des besoins
      de statistiques et d'affichage. Un cookies est une information déposée sur
      votre disque dur par le serveur du site que vous visitez. Il contient
      plusieurs données qui sont stockées sur votre ordinateur dans un simple
      fichier texte auquel un serveur accède pour lire et enregistrer des
      informations . Certaines parties de ce site ne peuvent être fonctionnelles
      sans l’acceptation de cookies.
    </p>

    <p>
      <strong>Liens hypertextes :</strong>
      Les sites internet de SAS Groupe Bourdoncle peuvent offrir des liens vers
      d’autres sites internet ou d’autres ressources disponibles sur Internet.
      SAS Groupe Bourdoncle ne dispose d'aucun moyen pour contrôler les sites en
      connexion avec ses sites internet. SAS Groupe Bourdoncle ne répond pas de
      la disponibilité de tels sites et sources externes, ni ne la garantit.
      Elle ne peut être tenue pour responsable de SAS Groupe Bourdoncle tout
      dommage, de quelque nature que ce soit, résultant du contenu de ces sites
      ou sources externes, et notamment des informations, produits ou services
      qu’ils proposent, ou de tout usage qui peut être fait de ces éléments. Les
      risques liés à cette utilisation incombent pleinement à l'internaute, qui
      doit se conformer à leurs conditions d'utilisation.
    </p>

    <p>
      Les utilisateurs, les abonnés et les visiteurs des sites internet de ne
      peuvent mettre en place un hyperlien en direction de ce site sans
      l'autorisation expresse et préalable de SAS Groupe Bourdoncle.
    </p>

    <p>
      Dans l'hypothèse où un utilisateur ou visiteur souhaiterait mettre en
      place un hyperlien en direction d’un des sites internet de SAS Groupe
      Bourdoncle , il lui appartiendra d'adresser un email accessible sur le
      site afin de formuler sa demande de mise en place d'un hyperlien. SAS
      Groupe Bourdoncle se réserve le droit d’accepter ou de refuser un
      hyperlien sans avoir à en justifier sa décision.
    </p>

    <p>
      <strong>Services fournis :</strong>
      L'ensemble des activités de la société ainsi que ses informations sont
      présentés sur notre site
      <a target="_blank" rel="noopener" href="http://www.patrol-paris.com"
        >www.patrol-paris.com</a
      >.
    </p>

    <p>
      SAS Groupe Bourdoncle s’efforce de fournir sur le site
      <a target="_blank" rel="noopener" href="http://www.patrol-paris.com"
        >www.patrol-paris.com</a
      >
      des informations aussi précises que possible. les renseignements figurant
      sur le site
      <a target="_blank" rel="noopener" href="http://www.patrol-paris.com"
        >www.patrol-paris.com</a
      >
      ne sont pas exhaustifs et les photos non contractuelles. Ils sont donnés
      sous réserve de modifications ayant été apportées depuis leur mise en
      ligne. Par ailleurs, tous les informations indiquées sur le site
      <a target="_blank" rel="noopener" href="http://www.patrol-paris.com"
        >www.patrol-paris.com</a
      >
      sont données à titre indicatif, et sont susceptibles de changer ou
      d’évoluer sans préavis.
    </p>

    <p>
      <strong>Limitation contractuelles sur les données :</strong>
      Les informations contenues sur ce site sont aussi précises que possible et
      le site remis à jour à différentes périodes de l’année, mais peut
      toutefois contenir des inexactitudes ou des omissions. Si vous constatez
      une lacune, erreur ou ce qui parait être un dysfonctionnement, merci de
      bien vouloir le signaler par email, à l’adresse contact@patrol-paris.com,
      en décrivant le problème de la manière la plus précise possible (page
      posant problème, type d’ordinateur et de navigateur utilisé, …).
    </p>

    <p>
      Tout contenu téléchargé se fait aux risques et périls de l'utilisateur et
      sous sa seule responsabilité. En conséquence, ne saurait être tenu
      responsable d'un quelconque dommage subi par l'ordinateur de l'utilisateur
      ou d'une quelconque perte de données consécutives au téléchargement. De
      plus, l’utilisateur du site s’engage à accéder au site en utilisant un
      matériel récent, ne contenant pas de virus et avec un navigateur de
      dernière génération mis-à-jour.
    </p>

    <p>
      Les liens hypertextes mis en place dans le cadre du présent site internet
      en direction d'autres ressources présentes sur le réseau Internet ne
      sauraient engager la responsabilité de SAS Groupe Bourdoncle.
    </p>

    <h2 class="policy-title">Données personnelles</h2>
    <p>
      De manière générale, vous n’êtes pas tenu de nous communiquer vos données
      personnelles lorsque vous visitez notre site Internet
      <a target="_blank" rel="noopener" href="http://www.patrol-paris.com"
        >www.patrol-paris.com</a
      >.
    </p>

    <p>
      Cependant, ce principe comporte certaines exceptions. En effet, pour
      certains services proposés par notre site, vous pouvez être amenés à nous
      communiquer certaines données telles que : votre nom, votre fonction, le
      nom de votre société, votre adresse électronique, et votre numéro de
      téléphone. Tel est le cas lorsque vous remplissez le formulaire qui vous
      est proposé en ligne, dans la rubrique « contact ». Dans tous les cas,
      vous pouvez refuser de fournir vos données personnelles. Dans ce cas, vous
      ne pourrez pas utiliser les services du site, notamment celui de
      solliciter des renseignements sur notre société, ou de recevoir les
      lettres d’information.
    </p>
    <p>
      Enfin, nous pouvons collecter de manière automatique certaines
      informations vous concernant lors d’une simple navigation sur notre site
      Internet, notamment : des informations concernant l’utilisation de notre
      site, comme les zones que vous visitez et les services auxquels vous
      accédez, votre adresse IP, le type de votre navigateur, vos temps d'accès.
      De telles informations sont utilisées exclusivement à des fins de
      statistiques internes, de manière à améliorer la qualité des services qui
      vous sont proposés. Les bases de données sont protégées par les
      dispositions de la loi du 1er juillet 1998 transposant la directive 96/9
      du 11 mars 1996 relative à la protection juridique des bases de données.
    </p>

    <h2 class="policy-title">Propriété intellectuelle</h2>
    <p>
      Tout le contenu du présent sur le site
      <a target="_blank" rel="noopener" href="http://www.patrol-paris.com"
        >www.patrol-paris.com</a
      >, incluant, de façon non limitative, les graphismes, images, textes,
      vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en
      forme sont la propriété exclusive de la société à l'exception des marques,
      logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs.
    </p>
    <p>
      Toute reproduction, distribution, modification, adaptation, retransmission
      ou publication, même partielle, de ces différents éléments est strictement
      interdite sans l'accord exprès par écrit de SAS Groupe Bourdoncle. Cette
      représentation ou reproduction, par quelque procédé que ce soit, constitue
      une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code
      de la propriété intellectuelle. Le non-respect de cette interdiction
      constitue une contrefaçon pouvant engager la responsabilité civile et
      pénale du contrefacteur. En outre, les propriétaires des Contenus copiés
      pourraient intenter une action en justice à votre encontre.
    </p>

    <p class="text-muted">
      Les présentes conditions du site
      <a target="_blank" rel="noopener" href="http://www.patrol-paris.com"
        >www.patrol-paris.com</a
      >
      sont régies par les lois françaises et toute contestation ou litiges qui
      pourraient naître de l'interprétation ou de l'exécution de celles-ci
      seront de la compétence exclusive des tribunaux dont dépend le siège
      social de la société. La langue de référence, pour le règlement de
      contentieux éventuels, est le français.
    </p>
  </main>
  <footer class="modal-footer" @click="$router.go(-1)">
    <span>Fermer</span>
  </footer>
</template>

<script>
export default {
  name: "LegalNotice"
};
</script>
